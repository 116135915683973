<template>
  <div class="container mb0-5">
    <b-card-group deck border-variant="dark" style="border: 0px solid transparent;">
      <b-card border-variant="dark" :title="$t('message.Company')" style="border: 0px solid transparent;">
        <!--<b-card-text>
          <a href="#" class="text-dark">
            <i class="fas fa-angle-right"></i>
            {{$t("message.Company_Dynamic")}}
          </a>
        </b-card-text>
        <b-card-text>
          <a href="#" class="text-dark">
            <i class="fas fa-angle-right"></i>
            {{$t("message.Talent_Recruitment")}}
          </a>
        </b-card-text>-->
        <!-- <b-card-text>
          <i class="fas fa-phone"></i> 86-4008890123
        </b-card-text> -->
        <b-card-text>
          <i class="far fa-envelope"></i> <a href="mailto:bd@dreamtimeip.com"  style="color:black;"> bd@dreamtimeip.com</a>
        </b-card-text>
      </b-card>

      <b-card border-variant="light" :title="$t('message.Payment_Way')" style="border: 0px solid transparent;">
        <b-card-text>
          <a
            href="https://www.paypal.com/c2/webapps/mpp/paypal-popup"
            title="How PayPal Works"
            onclick="javascript:window.open('https://www.paypal.com/c2/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"
          >
            <img
              src="https://www.paypalobjects.com/webstatic/mktg/logo/PP_AcceptanceMarkTray-NoDiscover_243x40.png"
              alt="Buy now with PayPal"
            />
          </a>
        </b-card-text>
      </b-card>

      <!-- <b-card border-variant="light" :title="$t('message.Product_Verification')" style="border: 0px solid transparent;">
        <b-card-text>
          <div class="input-group">
            <input
              class="form-control py-2 border-right-0 border"
              type="search"
              v-model="v_code"
              :placeholder="$t('message.Enter_your_product_code')"
            />
            <span class="input-group-append" @click="toVCode()">
              <div class="input-group-text bg-transparent">
                <i class="fas fa-search"></i>
              </div>
            </span>
          </div>
        </b-card-text>

        <b-card-text>
          <i class="far fa-envelope"></i> <a href="mailto:aftersales@bluedio.com"  style="color:black;"> aftersales@bluedio.com</a>
        </b-card-text>

        
      </b-card> -->

    </b-card-group>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FootSection",
  computed: mapGetters(["lan"]),
  data() {
    return {
      v_code: ""
    };
  },
  methods: {
    toManual() {
      this.$router.push({
        name: "enManual"
      });
    },

    toCustomize() {
      this.$router.push({
        name: "enCustomize"
      });
    },
    toVCode() {
      if (this.v_code == "" || this.v_code == 0) return;
      window.location =
        "http://code.fangwei315.com/yincaimacode.aspx?key=" + this.v_code;
    },
    toHome() {
      this.$router.push({
        name: "enhome"
      });
    }
  }
};
</script>
<style> 
  #facebook{ width:42px; height:42px} 

  #twitter{ width:52px; height:52px} 

  #instagram{ width:42px; height:42px}
</style> 