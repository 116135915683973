import { render, staticRenderFns } from "./FootSection.vue?vue&type=template&id=2b1f2a5b&"
import script from "./FootSection.vue?vue&type=script&lang=js&"
export * from "./FootSection.vue?vue&type=script&lang=js&"
import style0 from "./FootSection.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports