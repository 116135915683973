<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>

<template>
  <div>
    <Head-section></Head-section>
    <div>
    <div class="card mb-3">
      
      <img class="card-img-top" src="/img/dreamtime/tk/a-2.gif" alt="dream time">
      <div class="dt-logo">
        <img src="../assets/dt-logo2.png" alt="">
      </div>
      <div class="card-body">
        <p class="card-text" style="text-align:center;"><H4>{{ $t("message.Business_info") }}</H4></p>
      </div>
  </div>
  <br>

          <b-container fluid class="p-4 bg-light">
            <b-row v-for="(item, i) in tk_data9" :key="i" style="padding-top: 10px;">
              <b-col v-for="img_url in item" :key="img_url" sm="6">
                <b-img-lazy thumbnail fluid :src="img_url"></b-img-lazy>
              </b-col>
            </b-row>   
          </b-container>
    </div>
    <Foot-section></Foot-section>
  </div>
</template>

<script>
import HeadSection from "./HeadSection.vue";
import FootSection from "./FootSection.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Gallery",
  components: {
    HeadSection,
    FootSection
  },
  metaInfo() {
    return {
       title: this.$t("message.Bluedio_Mall") + " | Gallery",
       titleTemplate: "%s",
      meta: [
         {
          name: "keywords",
          content: "Gallery",
          
        }
      ],
    };
  },
  data() {
    return {
      tk_data1:[
        ["/img/dreamtime/tk7/102.png","/img/dreamtime/tk7/105.png"],
        ["/img/dreamtime/tk7/106.png","/img/dreamtime/tk7/10.png"],
        ["/img/dreamtime/tk7/112.png","/img/dreamtime/tk7/114.png"],
        ["/img/dreamtime/tk7/115.png","/img/dreamtime/tk7/116.png"],
        ["/img/dreamtime/tk7/117.png","/img/dreamtime/tk7/118.png"],
        ["/img/dreamtime/tk7/119.png","/img/dreamtime/tk7/11.png"],
        ["/img/dreamtime/tk7/122.png","/img/dreamtime/tk7/123.png"],
        ["/img/dreamtime/tk7/125.png","/img/dreamtime/tk7/126.png"],
        ["/img/dreamtime/tk7/127.png","/img/dreamtime/tk7/128.png"],
        ["/img/dreamtime/tk7/129.png","/img/dreamtime/tk7/13_2.png"],
        ["/img/dreamtime/tk7/134.png","/img/dreamtime/tk7/14.png"],
        ["/img/dreamtime/tk7/16.png","/img/dreamtime/tk7/1.png"],
        ["/img/dreamtime/tk7/2.png","/img/dreamtime/tk7/3.png"],
        ["/img/dreamtime/tk7/4.png","/img/dreamtime/tk7/5.png"],
        ["/img/dreamtime/tk7/60.png","/img/dreamtime/tk7/6.png"],
        ["/img/dreamtime/tk7/71.png","/img/dreamtime/tk7/73.png"],
        ["/img/dreamtime/tk7/77.png","/img/dreamtime/tk7/7.png"],
        ["/img/dreamtime/tk7/8.png","/img/dreamtime/tk7/91_1.png"],
        ["/img/dreamtime/tk7/91_3.png","/img/dreamtime/tk7/9_1.png"],
        ["/img/dreamtime/tk7/91.png","/img/dreamtime/tk7/9_2.png"],
        ],  
      tk_data9:[
        ["/img/dreamtime/tk9/100.jpg","/img/dreamtime/tk9/101.jpg"],
        ["/img/dreamtime/tk9/102.jpg","/img/dreamtime/tk9/103.jpg"],
        ["/img/dreamtime/tk9/104.jpg","/img/dreamtime/tk9/105.jpg"],
        ["/img/dreamtime/tk9/106.jpg","/img/dreamtime/tk9/107.jpg"],
        ["/img/dreamtime/tk9/108.jpg","/img/dreamtime/tk9/109.jpg"],
        ["/img/dreamtime/tk9/110.jpg","/img/dreamtime/tk9/111.jpg"],
        ["/img/dreamtime/tk9/113.jpg","/img/dreamtime/tk9/114.jpg"],
        ["/img/dreamtime/tk9/117.jpg","/img/dreamtime/tk9/119.jpg"],
        ["/img/dreamtime/tk9/120.jpg","/img/dreamtime/tk9/121.jpg"],
        ["/img/dreamtime/tk9/122.jpg","/img/dreamtime/tk9/123.jpg"],
        ["/img/dreamtime/tk9/124.jpg","/img/dreamtime/tk9/125_1.jpg"],
        ["/img/dreamtime/tk9/125_2.jpg","/img/dreamtime/tk9/125_3.jpg"],
        ["/img/dreamtime/tk9/125.jpg","/img/dreamtime/tk9/126.jpg"],
        ["/img/dreamtime/tk9/11.jpg","/img/dreamtime/tk9/0.jpg"],
        ["/img/dreamtime/tk9/12.jpg","/img/dreamtime/tk9/13.jpg"],
        ["/img/dreamtime/tk9/15.jpg","/img/dreamtime/tk9/16_11.jpg"],
        ["/img/dreamtime/tk9/17.jpg","/img/dreamtime/tk9/20.jpg"],
        ["/img/dreamtime/tk9/21.jpg","/img/dreamtime/tk9/223.jpg"],
        ["/img/dreamtime/tk9/30.jpg","/img/dreamtime/tk9/331.jpg"],
        ["/img/dreamtime/tk9/332.jpg","/img/dreamtime/tk9/333.jpg"],
        ["/img/dreamtime/tk9/334.jpg","/img/dreamtime/tk9/335.jpg"],
        ["/img/dreamtime/tk9/336.jpg","/img/dreamtime/tk9/40.jpg"],
        ["/img/dreamtime/tk9/60.jpg","/img/dreamtime/tk9/66.jpg"],
        ["/img/dreamtime/tk9/67.jpg","/img/dreamtime/tk9/68.jpg"],
        ["/img/dreamtime/tk9/70.jpg","/img/dreamtime/tk9/71.jpg"],
        ["/img/dreamtime/tk9/72.jpg","/img/dreamtime/tk9/73.jpg"],
        ["/img/dreamtime/tk9/74.jpg","/img/dreamtime/tk9/76.jpg"],
        ["/img/dreamtime/tk9/77.jpg","/img/dreamtime/tk9/78.jpg"],
        ["/img/dreamtime/tk9/79.jpg","/img/dreamtime/tk9/7.jpg"],
        ["/img/dreamtime/tk9/81.jpg","/img/dreamtime/tk9/83.jpg"],
        ["/img/dreamtime/tk9/85.jpg","/img/dreamtime/tk9/88.jpg"],
        ["/img/dreamtime/tk9/89.jpg","/img/dreamtime/tk9/8.png"],
        ["/img/dreamtime/tk9/90.jpg","/img/dreamtime/tk9/95.jpg"],
        ["/img/dreamtime/tk9/96.jpg","/img/dreamtime/tk9/97.jpg"],
        ["/img/dreamtime/tk9/99.jpg","/img/dreamtime/tk9/9.jpg"],
        ["/img/dreamtime/tk9/c1.jpg","/img/dreamtime/tk9/dog_run_6.jpg"],
        ["/img/dreamtime/tk9/dragon_run_1.jpg","/img/dreamtime/tk9/logo_n6.jpg"],
        ["/img/dreamtime/tk9/logo_new4.png"],
        ],  
      tk_data:[
        ["/img/dreamtime/tk4/2.png","/img/dreamtime/tk4/banner.jpg"],
        ["/img/dreamtime/tk4/1.png","/img/dreamtime/tk4/17.png"],
        ["/img/dreamtime/tk4/3.png","/img/dreamtime/tk4/4.png"],
        ["/img/dreamtime/tk4/8.png","/img/dreamtime/tk4/7.png"],
        ["/img/dreamtime/tk4/6.png","/img/dreamtime/tk4/6_1.png"],
        ["/img/dreamtime/tk4/5.png","/img/dreamtime/tk4/10.png"],
        ["/img/dreamtime/tk4/9_1.png","/img/dreamtime/tk4/9_2.png"],
        ["/img/dreamtime/tk4/11.png","/img/dreamtime/tk4/12.png"],
        ["/img/dreamtime/tk4/13_2.png","/img/dreamtime/tk4/14.png"],
        ["/img/dreamtime/tk4/15.png","/img/dreamtime/tk4/16.png"],
        ["/img/dreamtime/tk5/18.png","/img/dreamtime/tk5/19.png"],
        ["/img/dreamtime/tk5/20.png","/img/dreamtime/tk5/21.png"],
        ["/img/dreamtime/tk5/22.png","/img/dreamtime/tk5/23.png"],
        ["/img/dreamtime/tk5/24.png","/img/dreamtime/tk5/25.png"],
        ["/img/dreamtime/tk5/26.png","/img/dreamtime/tk5/27.png"],
        ["/img/dreamtime/tk5/27_1.png","/img/dreamtime/tk5/28.png"],
        ["/img/dreamtime/tk5/29.png","/img/dreamtime/tk5/30.png"],
        ["/img/dreamtime/tk5/31.png","/img/dreamtime/tk5/32.png"],
        ["/img/dreamtime/tk5/33.png","/img/dreamtime/tk6/68.png"],
        ["/img/dreamtime/tk6/69.png","/img/dreamtime/tk6/69_1.png"],
        ["/img/dreamtime/tk6/69_2.png","/img/dreamtime/tk6/69_3.png"],
        ["/img/dreamtime/tk6/70.png","/img/dreamtime/tk6/71.png"],
        ["/img/dreamtime/tk6/72.png","/img/dreamtime/tk6/73.png"],
        ["/img/dreamtime/tk6/74.png","/img/dreamtime/tk6/75.png"],
        ["/img/dreamtime/tk6/76.png","/img/dreamtime/tk6/77.png"],
        ["/img/dreamtime/tk6/78.png","/img/dreamtime/tk6/79.png"],
        ["/img/dreamtime/tk6/80.png","/img/dreamtime/tk6/81.png"],
        ["/img/dreamtime/tk6/82.png","/img/dreamtime/tk6/83.png"],
        ["/img/dreamtime/tk6/84.png","/img/dreamtime/tk6/85.png"],
        ["/img/dreamtime/tk6/86.png","/img/dreamtime/tk6/87.png"],
        ["/img/dreamtime/tk6/88.png","/img/dreamtime/tk6/89.png"],
        ["/img/dreamtime/tk6/90.png","/img/dreamtime/tk6/91.png"],
        ["/img/dreamtime/tk6/92.png","/img/dreamtime/tk6/93.png"],
        ["/img/dreamtime/tk6/94.png","/img/dreamtime/tk6/95.png"],
        ["/img/dreamtime/tk6/96.png","/img/dreamtime/tk6/97.png"],
        ["/img/dreamtime/tk6/98.png","/img/dreamtime/tk6/99.png"],
        ["/img/dreamtime/tk6/100.png","/img/dreamtime/tk6/101.png"],
        ["/img/dreamtime/tk6/102.png"],
        ],  
    };
  },
  created() {
    
  },
  computed: {
    ...mapGetters([
      "lan","iszh","ismob"
    ])
  },

  watch: {
  },
  methods: {
  },
};
</script>
<style>
.dt-logo img{
 position: absolute;
 width: 24%;
 left: 20px;
 top: 20px;
 z-index: 9998;
}
.card {
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.0rem;
}
.divider {
    height: 1px;
    margin-top: 40px;
    text-align:center;
    border-top: 1px solid #e2e2e2;
}
.divider span{
    position: relative;
    top: -20px;
    background: #fff;
    font-size: 28px;
    font-weight: bold;
    padding: 0 24px;
}

.blurDiv{
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.alert-light {
    color: #1b1e21;
    background-color: white;
    border-color: white;
    text-align: center;
}
</style>